var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-manage-base',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$route.name))]},proxy:true},{key:"default",fn:function(){return [_c('Table',{ref:"table",attrs:{"attr":{
        dense: true,
        'no-data-text': _vm.$t('rules.noData'),
        'item-key': 'id',
        class: 'mt-5 ml-10 mr-10 custom-table',
      },"total":_vm.getTotalDuplicatedClientList,"items":_vm.itemsData.duplicatedClientList,"itemsPerPage":10,"itemsPerPageOptions":[10, 20, 50, 100, 200, 500],"headers":_vm.headers,"funReset":_vm.getDuplicatedClientListInital},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
      var item = ref.item;
return [(item.type === 'Brochure')?_c('span',{staticStyle:{"margin-left":"16px","margin-right":"16px"}},[_vm._v("資料請求")]):(item.type === 'TrialStay')?_c('span',{staticStyle:{"margin-left":"16px","margin-right":"16px"}},[_vm._v("体験宿泊")]):(item.type === 'Visit')?_c('span',{staticStyle:{"margin-left":"16px","margin-right":"16px"}},[_vm._v("来店予約")]):(item.type === 'OnlineCounseling')?_c('span',{staticStyle:{"margin-left":"16px","margin-right":"16px"}},[_vm._v("オンライン相談")]):(item.type === 'VS')?_c('span',{staticStyle:{"margin-left":"16px","margin-right":"16px"}},[_vm._v("VS入会")]):_c('span',{staticStyle:{"margin-left":"16px","margin-right":"16px"}})]}},{key:"item.ClientName",fn:function(ref){
      var item = ref.item;
return _vm._l((item.duplicatedItems),function(val,i){return _c('div',{key:i},[_c('span',[_vm._v(_vm._s(val.client.name))])])})}},{key:"item.baseInfo",fn:function(ref){
      var item = ref.item;
return _vm._l((item.duplicatedItems),function(val,i){return _c('div',{key:i},[(item.baseInfo)?_c('v-btn',{staticStyle:{"background-color":"#13ace0 !important","width":"30px","height":"30px"},attrs:{"icon":"","fab":""},on:{"click":function($event){return _vm.redirectToCustomerDetail(val.client)}}},[_c('v-icon',{attrs:{"color":"#FFFFFF","size":"24"}},[_vm._v("mdi-account")])],1):_vm._e()],1)})}},{key:"item.MatchContent",fn:function(ref){
      var item = ref.item;
return _vm._l((item.duplicatedItems),function(val,i){return _c('div',{key:i},[_c('span',[_vm._v(_vm._s(val.duplicatedFields.map(function (c) { return c.name; }).join(', ')))])])})}},{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return _vm._l((item.duplicatedItems),function(val,i){return _c('div',{key:i},[_c('span',[_vm._v(_vm._s(_vm.formatDate(val.client.createdAt)))])])})}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return _vm._l((item.duplicatedItems),function(val,i){return _c('div',{key:i},[(item.baseInfo)?_c('v-btn',{staticStyle:{"width":"30px","height":"30px"},style:(_vm.checkPerUser
                ? 'background-color: #13ace0 !important'
                : 'background-color: #bdbdbd !important'),attrs:{"icon":"","fab":"","disabled":!_vm.checkPerUser},on:{"click":function($event){(_vm.visible = 1), (_vm.itemData = item)}}},[_c('v-icon',{attrs:{"color":"#FFFFFF","size":"22"}},[_vm._v(" mdi-pencil ")])],1):_vm._e()],1)})}},(_vm.checkPerUser)?{key:"item.btnAdd",fn:function(ref){
                var item = ref.item;
return _vm._l((item.duplicatedItems),function(val,i){return _c('div',{key:i},[_c('v-btn',{staticStyle:{"font-size":"14px","margin-left":"16px","margin-right":"16px"},attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.addToDuplicateCustomers(item, val)}}},[_vm._v("重複先顧客に追加")])],1)})}}:null,{key:"item.btnDoNotAdd",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"more-action-btn",attrs:{"small":"","disabled":!_vm.checkPerUser,"outlined":"","color":"primary"},on:{"click":function($event){return _vm.doNotAddToDuplicateCustomers(item)}}},[_vm._v(" 追加しない ")]),(item.type === 'VS')?_c('v-btn',{staticClass:"more-action-btn",attrs:{"small":"","disabled":!_vm.checkPerUser,"outlined":"","color":"error"},on:{"click":function($event){return _vm.dismissVsSignup(item)}}},[_vm._v(" 削除 ")]):_vm._e()],1)]}}],null,true)}),(_vm.checkPerUser)?_c('InformationRequestDetail',{attrs:{"data":_vm.itemData,"visible":_vm.visible === 1},on:{"close":function($event){_vm.visible = -1}}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }
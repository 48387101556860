<template>
  <div>
    <v-dialog v-model="dialog">
      <div class="justify-space-between d-flex">
        <h1>VS入会重複</h1>
        <div class="justify-space-between d-flex">
          <v-btn class="t-btn--red-dark" @click="closeDialog">
            <v-icon color="#FFFFFF" size="22"> mdi-close </v-icon>
            閉じる
          </v-btn>
        </div>
      </div>
      <div class="mt-1">
        <h2>入会情報</h2>
        <div class="content-container" v-if="baseInfo">
          <div>
            <span
              >個人/法人:<span v-if="baseInfo.isCompany === true">法人</span
              ><span v-else-if="baseInfo.isCompany === false">個人</span></span
            >
          </div>
          <div>
            <span
              >メールアドレス:<span>{{ baseInfo.email }}</span></span
            >
          </div>
          <div>
            <span
              >入力会員番号:<span>{{ baseInfo.memberId }}</span></span
            >
          </div>
          <div>
            <span
              >氏名/法人名:<span>{{ baseInfo.name }}</span></span
            >
          </div>
          <div>
            <span
              >性別:<span>
                {{ baseInfo.sex | sex }}
              </span></span
            >
          </div>
          <div>
            <span
              >生年月日:<span>{{ baseInfo.birthday }}</span></span
            >
          </div>
          <div>
            <span
              >郵便番号:<span>{{ baseInfo.postalCode }}</span></span
            >
          </div>
          <div>
            <span
              >住所:
              <span v-if="baseInfo.prefecture">
                {{ baseInfo.prefecture }}
              </span>
              <span v-if="baseInfo.locality">
                <span v-if="baseInfo.prefecture">-</span>
                {{ baseInfo.locality }}
              </span>
              <span v-if="baseInfo.address1">
                <span v-if="baseInfo.prefecture || baseInfo.locality">-</span>
                {{ baseInfo.address1 }}
              </span>
              <span v-if="baseInfo.address2">
                <span
                  v-if="
                    baseInfo.prefecture ||
                      baseInfo.locality ||
                      baseInfo.address1
                  "
                  >-</span
                >
                {{ baseInfo.address2 }}
              </span>
            </span>
          </div>
          <div>
            <span
              >電話番号:<span>{{ baseInfo.tel }}</span></span
            >
          </div>
          <div>
            <span
              >携帯電話番号:<span>{{ baseInfo.telMobile }}</span></span
            >
          </div>
          <template v-if="data.vsApplication">
            <div>契約特典: {{ data.vsApplication.signupBonus ? data.vsApplication.signupBonus.name : 'なし' }}</div>
            <div>特典コード: {{ data.vsApplication.signupBonus ? data.vsApplication.signupBonus.vsPromoCode : 'なし' }}</div>
          </template>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'informationRequestDetai',
  data() {
    return {
      PrivateCorporation: '個人',
      emailAddress: 'XXXXXXXXXXXXXXXXXX',
      EnterMembershipNumber: 'XXXXXXXX',
      corporateName: 'XXXXXXXX',
      sex: 'XXX',
      Birthday: 'XXXXXXX',
      PostalCode: 'XXXXXXX',
      address: 'XXXXXXXXX',
      telephoneNumber: 'XXXXXXXXXXXXXXXXX',
    };
  },
  props: {
    visible: Boolean,
    data: Object,
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    baseInfo () {
      return this.data.baseInfo
    }
  },
  methods: {
    save() {
      this.$emit('close');
    },
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
// -- Dialog --
body {
  overflow: hidden;
}
h1{
  font-size: 22px !important;
  color: #000000 !important;
  font-weight: bold !important;
}
h2{
  font-size: 20px !important;
  font-weight: bold !important;
  color: #0B6786 !important;
}
::v-deep {
  .v-dialog {
    width: 490px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
}
.content-container {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #1B1B1B !important;
}
</style>

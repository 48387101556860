<template>
  <page-manage-base>
    <template v-slot:title>{{ $route.name }}</template>
    <template v-slot:default>
      <!-- :items="fakeData" -->
      <Table
        :attr="{
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
          class: 'mt-5 ml-10 mr-10 custom-table',
        }"
        ref="table"
        :total="getTotalDuplicatedClientList"
        :items="itemsData.duplicatedClientList"
        :itemsPerPage="10"
        :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
        :headers="headers"
        :funReset="getDuplicatedClientListInital"
      >
        <template v-slot:[`item.type`]="{ item }">
          <span
            v-if="item.type === 'Brochure'"
            style="margin-left: 16px; margin-right: 16px"
            >資料請求</span
          >
          <span
            v-else-if="item.type === 'TrialStay'"
            style="margin-left: 16px; margin-right: 16px"
            >体験宿泊</span
          >
          <span
            v-else-if="item.type === 'Visit'"
            style="margin-left: 16px; margin-right: 16px"
            >来店予約</span
          >
          <span
            v-else-if="item.type === 'OnlineCounseling'"
            style="margin-left: 16px; margin-right: 16px"
            >オンライン相談</span
          >
          <span
            v-else-if="item.type === 'VS'"
            style="margin-left: 16px; margin-right: 16px"
            >VS入会</span
          >
          <span v-else style="margin-left: 16px; margin-right: 16px"></span>
        </template>
        <template v-slot:[`item.ClientName`]="{ item }">
          <div v-for="(val, i) in item.duplicatedItems" :key="i">
            <!--class="border-bottom" -->
            <span>{{ val.client.name }}</span>
          </div>
        </template>
        <template v-slot:[`item.baseInfo`]="{ item }">
          <div v-for="(val, i) in item.duplicatedItems" :key="i">
            <v-btn
              v-if="item.baseInfo"
              icon
              fab
              style="
                background-color: #13ace0 !important;
                width: 30px;
                height: 30px;
              "
              @click="redirectToCustomerDetail(val.client)"
            >
              <v-icon color="#FFFFFF" size="24">mdi-account</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.MatchContent`]="{ item }">
          <div v-for="(val, i) in item.duplicatedItems" :key="i">
            <!-- class="border-bottom" -->
            <span>{{ val.duplicatedFields.map(c => c.name).join(', ') }}</span>
          </div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <div v-for="(val, i) in item.duplicatedItems" :key="i">
            <span>{{ formatDate(val.client.createdAt) }}</span>
          </div>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <div v-for="(val, i) in item.duplicatedItems" :key="i">
            <v-btn
              v-if="item.baseInfo"
              icon
              fab
              :disabled="!checkPerUser"
              style="width: 30px; height: 30px;"
              :style="
                checkPerUser
                  ? 'background-color: #13ace0 !important'
                  : 'background-color: #bdbdbd !important'
              "
              @click="(visible = 1), (itemData = item)"
            >
              <v-icon color="#FFFFFF" size="22">
                mdi-pencil
              </v-icon>
            </v-btn>
          </div>
        </template>
        <template v-if="checkPerUser" v-slot:[`item.btnAdd`]="{ item }">
          <div v-for="(val, i) in item.duplicatedItems" :key="i">
            <!-- class="border-bottom" -->
            <v-btn
              small
              outlined
              @click="addToDuplicateCustomers(item, val)"
              color="primary"
              style="font-size: 14px; margin-left: 16px; margin-right: 16px"
              >重複先顧客に追加</v-btn
            >
          </div>
        </template>
        <template v-slot:[`item.btnDoNotAdd`]="{ item }">
          <div class="d-flex">
            <!-- :disabled="!checkPerUser && item.duplicatedItems.length !== 0" -->
            <v-btn
              small
              class="more-action-btn"
              :disabled="!checkPerUser"
              outlined
              @click="doNotAddToDuplicateCustomers(item)"
              color="primary"
            >
              追加しない
            </v-btn>
            <v-btn
              v-if="item.type === 'VS'"
              class="more-action-btn"
              small
              :disabled="!checkPerUser"
              outlined
              @click="dismissVsSignup(item)"
              color="error"
            >
              削除
            </v-btn>
          </div>
        </template>
      </Table>
      <InformationRequestDetail
        v-if="checkPerUser"
        :data="itemData"
        :visible="visible === 1"
        @close="visible = -1"
      />
    </template>
  </page-manage-base>
</template>

<script>
import PageManageBase from '../PageManageBase.vue';
import InformationRequestDetail from './informationRequestDetail.vue';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import Table from '@/components/Table/index.vue';

export default {
  name: 'InfomationRequest',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      visible: -1,
      itemData: null,
      // Table
      headers: [
        {
          text: '内容',
          sortable: false,
          value: 'type',
        },
        // 重複先顧客名
        {
          text: this.$t('webPageManage.infoRequest.DuplicateCustomerName'),
          sortable: false,
          value: 'ClientName',
          align: 'center',
        },
        // 重複先顧客詳細 Do not description
        {
          text: this.$t('webPageManage.infoRequest.DuplicateCustomerDetails'),
          sortable: false,
          value: 'baseInfo',
          align: 'center',
        },
        // 一致内容
        {
          text: this.$t('webPageManage.infoRequest.MatchContent'),
          sortable: false,
          value: 'MatchContent',
        },
        // 受信日
        {
          text: this.$t('webPageManage.infoRequest.ReceivedDate'),
          sortable: false,
          value: 'createdAt',
        },
        // 詳細
        {
          text: this.$t('webPageManage.infoRequest.detail'),
          sortable: false,
          value: 'edit',
          align: 'center',
        },
        //
        { text: '', sortable: false, value: 'btnAdd', width: '100' },
        //
        {
          text: '',
          sortable: false,
          value: 'btnDoNotAdd',
          width: '150px',
          align: 'center',
        },
      ],
      itemsData: {
        duplicatedClientList: [],
      },
    };
  },

  computed: {
    ...mapGetters(['getTotalDuplicatedClientList']),
  },

  mounted() {
    this.$refs.table.reset();
  },
  methods: {
    ...mapActions([
      'actionDuplicatedClientList',
      'actionAssignClientWebRequest',
      'actionVsSignupTransferContractOwnership',
      'actionCreateClientWebRequest',
      'actionVsSignupDuplicateCheck',
      'actionDismissVsSignup'
    ]),
    async getDuplicatedClientListInital(variables) {
      if (variables) {
        await this.actionDuplicatedClientList(variables.pagination).then(
          duplicatedClientList => {
            this.itemsData.duplicatedClientList = duplicatedClientList;
          },
        );
      }
    },
    formatDate(date) {
      return moment(date).format('YYYY/MM/DD');
    },
    addToDuplicateCustomers(item, val) {
      this.$confirm({
        title: '重複先顧客に追加',
        message: '選択された申し込みは重複と判断し、重複先顧客に追加して登録します。よろしいですか？（この処理は取り消せません）',
        ok: async () => {
          if (item.type !== 'VS') {
            const data = {
              webRequestId: item.webRequest.id,
              clientId: val.client.id,
            };
            this.actionAssignClientWebRequest(data).then(() =>
              this.$refs.table.reset(),
            );
          } else {
            const data = {
              vsApplicationId: item.vsApplication.id,
              clientId: val.client.id,
            };
            this.actionVsSignupTransferContractOwnership(data).then(() =>
              this.$refs.table.reset(),
            );
          }
        },
      })
    },
    doNotAddToDuplicateCustomers(item) {
      this.$confirm({
        title: '新規顧客登録',
        message: '選択された申し込みは重複ではないとし、新規顧客として登録します。よろしいですか？（この処理は取り消せません）',
        ok: async () => {
          if (item.type !== 'VS') {
            const data = {
              webRequestId: item.webRequest.id,
            };
            await this.actionCreateClientWebRequest(data).then(() =>
              this.$refs.table.reset(),
            );
          } else {
            const data = {
              vsApplicationId: item.vsApplication.id,
            };
            await this.actionVsSignupDuplicateCheck(data).then(() =>
              this.$refs.table.reset(),
            );
          }
        },
      })
    },
    dismissVsSignup (item) {
      if (item.type === 'VS') {
        this.$confirm({
          title: 'VS入会申込削除',
          message: '選択されたVS入会申込を削除します。よろしいですか？（この処理は取り消せません）',
          ok: async () => {
            const data = {
              vsApplicationId: item.vsApplication.id,
            };
            await this.actionDismissVsSignup(data);
            this.$refs.table.reset()
          },
        })
      }
    },
    redirectToCustomerDetail(item) {
      window.open(
        `/customer-details/${item.id}?idClient=${item.id}&typeClient=${item.type}`,
      );
    },
  },
  components: {
    PageManageBase,
    InformationRequestDetail,
    Table,
  },
};
</script>

<style lang="scss" scoped>
.more-action-btn {
  font-size: 14px;
  margin-top: 10px;
  margin-left: 16px;
  margin-right: 16px
}

::v-deep {
  .custom-table {
    thead > tr > th {
      font-weight: bold !important;
      color: #757575 !important;
      font-size: 14px !important;
    }

    tbody > tr {
      line-height: 40px !important;
      td {
        color: #757575 !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        &:nth-child(2) {
          div {
            padding: 4px 0;
            // padding: 2px 0;
          }
          div:not(:last-child) {
            border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
          }
        }
        &:nth-child(3) {
          div {
            padding: 4px 0;
            // padding: 2px 0;
          }
          div:not(:last-child) {
            border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
          }
        }
        &:nth-child(4) {
          div {
            padding: 4px 0;
            // padding: 2px 0;
          }
          div:not(:last-child) {
            border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
          }
        }
        &:nth-child(5) {
          div {
            padding: 4px 0;
            // padding: 2px 0;
          }
          div:not(:last-child) {
            border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
          }
        }
        &:nth-child(6) {
          div {
            padding: 4px 0;
            // padding: 2px 0;
          }
          div:not(:last-child) {
            border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
          }
        }
        &:nth-child(7) {
          div {
            padding: 4px 0;
          }
          div:not(:last-child) {
            border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
          }
        }
        &:nth-child(8) {
          vertical-align: top;
        }
      }
    }
    .v-data-footer__select {
      color: #000000;
      font-weight: 500;
      font-size: 13px !important;
      .v-select__selection--comma {
        color: #000000;
        font-weight: 500;
        font-size: 13px !important;
      }
    }

    .v-data-footer__pagination {
      display: none !important;
    }
    .v-input__append-inner {
      .v-icon__svg {
        color: #333333 !important;
      }
    }
  }
}
</style>
